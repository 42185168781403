<template lang="pug">
    .main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub / Agenda / <b>Lista de Bloqueio</b>

        Dialog.dialogApagar(header='Remover Bloqueio' :visible.sync='dialogApagar' :modal='true')
            .ta-center.my-4
                p Deseja remover o bloqueio da agenda <b v-if="dialogApagar_data.cd_agenda">{{ dialogApagar_data.cd_agenda.id }}</b>?
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        Dialog.dialogBloquear(v-if='model' header='Editar bloqueio' :modal='true'
            :visible.sync='dialogEditar' :contentStyle="{overflow: 'visible'}")

            form(@submit.prevent='EditarBloqueio()')
                .p-grid.p-fluid
                    .p-col-12.p-md-6
                        label.form-label Data Inicio:
                        .p-inputgroup
                            Calendar(
                                v-model='model.dt_inicio_bloqueio'
                                dateFormat="dd/mm/yy"
                                :locale="ptbr"
                                :minDate='minData'
                                :manualInput='false'
                                :touchUI='windowInnerWidth < 576')
                            Button.p-button-danger(
                                icon='jam jam-rubber' type='button'
                                v-tooltip.top="'Limpar'"
                                @click='model.dt_inicio_bloqueio=null')
                    .p-col-12.p-md-6
                        label.form-label Data Fim:
                        .p-inputgroup
                            Calendar(
                                v-model='model.dt_fim_bloqueio' 
                                dateFormat="dd/mm/yy" 
                                :locale="ptbr" 
                                :minDate='minData'
                                :manualInput='false'
                                :touchUI='windowInnerWidth < 576')
                            Button.p-button-danger(
                                icon='jam jam-rubber' type='button'
                                v-tooltip.top="'Limpar'"
                                @click='model.dt_fim_bloqueio=null')

                    .p-col-12.p-md-6
                        label.form-label Horário de início:
                        InputMask(type='tel' v-model='model.hr_inicio' mask='99:99' placeholder='hh:mm')

                    .p-col-12.p-md-6
                        label.form-label Horário de fim:
                        InputMask(type='tel' v-model='model.hr_fim' mask='99:99' placeholder='hh:mm')

                    .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_motivo_bloqueio.$error }")
                        label.form-label Motivo do Bloqueio:
                        Textarea(type='text' v-model='$v.model.ds_motivo_bloqueio.$model' :autoResize="true" rows="3")
                        .feedback--errors(v-if='submitted && $v.model.ds_motivo_bloqueio.$error')
                            .form-message--error(v-if="!$v.model.ds_motivo_bloqueio.minLength") <b>Motivo</b> deve ter pelo menos 2 caracteres.
                            .form-message--error(v-if="!$v.model.ds_motivo_bloqueio.required") <b>Motivo</b> é obrigatório.

                    .p-col-6
                        span <b>Usuario que criou: </b> {{ model.nm_usuario_cri }}
                    .p-col-6
                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                    .p-col-6.mt
                        span <b>Usuario que editou: </b> {{ model.nm_usuario_edi }}
                    .p-col-6
                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                .ta-center.mt-4(v-if='waitingExecutar')
                    ProgressSpinner.waiting-executar-spinner(strokeWidth='6')
                .ta-center.mt-4(v-else)
                    Button.p-button-success(label='Salvar' type="submit")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.cd_agenda.nm_especialista" style='position: relative')
                            .ta-left
                                p <b>Cód.:</b> {{ props.data.cd_agenda.id }}
                                p <b>Motivo:</b> {{ props.data.ds_motivo_bloqueio }}
                                p <b>Data início:</b> {{ props.data.dt_inicio_bloqueio }}
                                p <b>Data fim:</b> {{ props.data.dt_fim_bloqueio }}
                                p <b>Hora início:</b> {{ props.data.hr_inicio }}
                                p <b>Hora fim:</b> {{ props.data.hr_fim }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Bloqueios')
                DataTable(:value="list")

                    Column(headerStyle='width: 6%;' field='cd_agenda' header='Cód.' bodyStyle='text-align: center;')
                    Column(headerStyle='width: 20%;' field='ds_motivo_bloqueio' header='Motivo')
                    Column(headerStyle='width: 10%;' field='dt_inicio_bloqueio' header='Data Início' bodyStyle='text-align: center;')
                    Column(headerStyle='width: 10%;' field='dt_fim_bloqueio' header='Data Fim' bodyStyle='text-align: center;')
                    Column(headerStyle='width: 10%;' field='hr_inicio' header='Hora Início' bodyStyle='text-align: center;')
                    Column(headerStyle='width: 10%;' field='hr_fim' header='Hora Fim' bodyStyle='text-align: center;')
                    Column(headerStyle='width: 12%;' field='dt_criado_f' header='Criação' bodyStyle='text-align: center;')
                        template(#body="props")
                            span <b>{{ props.data.nm_usuario_cri }}</b><br>
                            span {{ props.data.dt_criado_f }}
                    Column(headerStyle='width: 12%;' field='dt_atualizado_f' header='Edição' bodyStyle='text-align: center;')
                        template(#body="props")
                            span <b>{{ props.data.nm_usuario_edi }}</b><br>
                            span {{ props.data.dt_atualizado_f }}
                    Column(headerStyle='width: 10%;' header="Ações")
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="openDialogEditar(props.data)"
                                    )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-tooltip.top="'Apagar'"
                                    icon="jam jam-minus-circle"
                                    @click="dialogApagar=true; dialogApagar_data=props.data"
                                    )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            width: 350px;
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .dialogBloquear {
            width: 550px;
            .waiting-executar-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import Button from 'primevue/button'
    import Calendar from 'primevue/calendar'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import DataView from 'primevue/dataview'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Textarea from 'primevue/textarea'
    import Tooltip from 'primevue/tooltip'

    import { BloqueioAgenda } from './../../middleware'
    import wsConfigs from './../../middleware/configs'

    import moment from 'moment'
    import { pCalendarLocale_ptbr } from './../../utils'
    import { required, minLength } from 'vuelidate/lib/validators'

    export default {
        components: { Button, Calendar, Column, DataTable, DataView, Dialog, InputText, InputMask,
            Paginator, Panel, ProgressBar, ProgressSpinner, Textarea, Tooltip },
        directives: { tooltip: Tooltip },
        created () { this.applyFilters() },
        data () {
            return {
                list: [],
                model: {
                    id: null,
                    ds_motivo_bloqueio: null,
                    ds_observacao_bloqueio: null,
                    dt_inicio_bloqueio: null,
                    dt_fim_bloqueio: null,
                    hr_inicio: null,
                    hr_fim: null,
                    nm_usuario_cri: null,
                    nm_usuario_edi: null,
                    dt_criado_f: null,
                    dt_atualizado_f: null,
                },
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingExecutar: false,
                minData: new Date(),
                dialogApagar: false,
                dialogApagar_data: {},
                dialogEditar: false,
                dialogBloquear_data: {},
                ptbr: pCalendarLocale_ptbr,
                submitted: false,
                filters: {
					nm_unidade: ''
				},
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        validations () {
            let v = {
                model: {
                    ds_motivo_bloqueio: { required, minLength: minLength(2) }
                }
            }
            return v
        },
        methods: {
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            applyFilters () {
                let params = {
                    cd_agenda: parseInt(this.$route.params.id),
                    paginacao: true,
                    page: this.paginator.page,
                    per_page: this.paginator.per_page
                }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key] })
                this.getList(params)
            },
            getList (params) {
                this.waiting = true
                BloqueioAgenda.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                        this.list.forEach(bloqueio => {
                            bloqueio.dt_inicio_bloqueio = bloqueio.dt_inicio_bloqueio
                                ? moment(bloqueio.dt_inicio_bloqueio).format('DD/MM/YYYY') : ""
                            bloqueio.dt_fim_bloqueio = bloqueio.dt_fim_bloqueio
                                ? moment(bloqueio.dt_fim_bloqueio).format('DD/MM/YYYY') : ""
                            bloqueio.hr_inicio = bloqueio.hr_inicio
                                ? moment(bloqueio.hr_inicio, 'HH:mm:SS').format('HH:mm') : ""
                            bloqueio.hr_fim = bloqueio.hr_fim
                                ? moment(bloqueio.hr_fim, 'HH:mm:SS').format('HH:mm') : ""
                            bloqueio.dt_criado_f = bloqueio.dt_criado
                                ? moment(bloqueio.dt_criado).format('DD/MM/YYYY') : ""
                            bloqueio.dt_atualizado_f = bloqueio.dt_atualizado
                                ? moment(bloqueio.dt_atualizado).format('DD/MM/YYYY') : ""
                        })
                    }
                    this.waiting = false
                })
            },
            openDialogEditar(data) {
                const keys = Object.keys(this.model)
                keys.forEach(key => this.model[key] = data[key])
                this.model.cd_agenda = data.cd_agenda.id

                this.dialogEditar = true
            },
            EditarBloqueio() {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return

                let dataSend = Object.assign({}, this.model)
                dataSend.dt_inicio_bloqueio = moment(this.model.dt_inicio_bloqueio, 'DD/MM/YYYY').format('YYYY-MM-DD')
                dataSend.dt_fim_bloqueio = moment(this.model.dt_fim_bloqueio, 'DD/MM/YYYY').format('YYYY-MM-DD')

                this.waitingExecutar = true
                BloqueioAgenda.save(dataSend).then(response => {
                    this.waitingExecutar = false
                    if([200, 201].includes(response.status)) {
                        this.dialogEditar = false
                        this.applyFilters()
                        this.$toast.success('Bloqueio atualizado com sucesso.', { duration: 3000 })
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            remove () {
                this.waitingApagar = true
                BloqueioAgenda.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Bloqueio removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>
